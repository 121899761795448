/**
 * @Module ItemTypeConstants
 */
import { browserPolicyNameToBrowser, ospPolicyNameToOs } from 'client/util/Constants/OsPatchMgmtConstants';
import DirectoryGroupingConstants from 'client/util/Constants/DirectoryGroupingConstants';

/**
 * The ItemTypes constants define the different types of items that may be
 * displayed by a Vuex CollectionModule instance. As other object types from
 * JumpCloud are ported to Vue/Vuex they will be added here.
 * @todo Add an ItemTypeNameConts hash that references this and can be used
 * for singular and plural labels for each ItemType so that these labels aren't
 * repeated through out the code (e.g. System, Systems, System Group, System
 * Groups, etc).
 */
const ItemTypeConsts = /* @alphabetize */ {
  account: 'account',
  accountBilling: 'accountBilling',
  accountGeneral: 'accountGeneral',
  accountInvoices: 'accountInvoices',
  accountUpgrade: 'accountUpgrade',
  activeDirectory: 'active_directory',
  adBridgeAgent: 'adBridgeAgent',
  addOn: 'addOn',
  alerts: 'alerts',
  appAuthorization: 'appAuthorization',
  apple: 'apple',
  application: 'application',
  applicationTemplate: 'application_template',
  applications: 'applications',
  asidePanelEvents: 'asidePanelEvents',
  authNPolicies: 'authNPolicies',
  authNPoliciesAside: 'authNPoliciesAside',
  authNPoliciesSettings: 'authNPoliciesSettings',
  bambooHR: 'bamboo_hr',
  bookmark: 'bookmark',
  browserPatchManagement: 'browserPatchManagement',
  certificates: 'certificates',
  chat: 'chat',
  cloudDirectory: 'cloudDirectory',
  command: 'command',
  commandCustomTemplate: 'commandCustomTemplate',
  commandQueue: 'commandQueue',
  commandQueueWorkflow: 'commandQueueWorkflow',
  commandQueues: 'commandQueues',
  commandResult: 'commandResult',
  commandResults: 'commandResults',
  commandRunner: 'commandRunner',
  commandTemplate: 'commandTemplate',
  commandTemplateEdit: 'commandTemplateEdit',
  commandTemplateLinux: 'commandTemplateLinux',
  commandTemplateMacOs: 'commandTemplateMacOs',
  commandTemplateWindows: 'commandTemplateWindows',
  configuration: 'configuration',
  configurationGroup: 'policy_group',
  customApps: 'customApp',
  customPolicyGroupTemplates: 'customPolicyGroupTemplates',
  defaultPolicyGroupTemplates: 'defaultPolicyGroupTemplates',
  device: 'device',
  deviceAppStatus: 'deviceAppStatus',
  deviceCustomAppStatus: 'deviceCustomAppStatus',
  deviceCustomSelfHostedAppStatus: 'deviceCustomSelfHostedAppStatus',
  deviceGroup: 'deviceGroup',
  deviceList: 'deviceList',
  deviceOverview: 'deviceOverview',
  devices: 'devices',
  dgSystemUsersPreview: 'dgSystemUsersPreview',
  dgSystemsPreview: 'dgSystemsPreview',
  dgSystemsReview: 'dgSystemsReview',
  dgUsersReview: 'dgUsersReview',
  directories: 'directories',
  directory: 'directory',
  downloads: 'downloads',
  featureFlags: 'featureFlags',
  gSuite: 'g_suite',
  geolocation: 'geolocation',
  google: 'google',
  googleEmmEnrollmentTokens: 'googleEmmEnrollmentTokens',
  group: 'group',
  growth: 'growth',
  home: 'home',
  hrDirectories: 'hrDirectories',
  hrDirectory: 'hrDirectory',
  identityProviders: 'identityProviders',
  idsource: 'idsource',
  importUsers: 'importUsers',
  insightsEvent: 'insightsEvent',
  ipList: 'ipList',
  knowledge: 'knowledge',
  ldap: 'ldap_server',
  mdmEvents: 'mdmEvents',
  mdmGoogle: 'mdmGoogle',
  mdmSettings: 'mdmSettings',
  mdmVpp: 'mdmVpp',
  mfaSettings: 'mfaSettings',
  microsoftStoreSearch: 'microsoftStoreSearch',
  notification: 'notification',
  office365: 'office_365',
  okta: 'okta',
  organizationInvoice: 'organizationInvoice',
  organizations: 'organizations',
  osManagement: 'osManagement',
  osPatchManagement: 'osPatchManagement',
  partners: 'partners',
  passwordManagement: 'passwordManagement',
  passwordManager: 'passwordManager',
  patchManagement: 'patchManagement',
  payment: 'payment',
  plan: 'plan',
  policy: 'policy',
  policyGroupTemplate: 'policyGroupTemplate',
  policyGroupTemplateEdit: 'policyGroupTemplateEdit',
  policyStatus: 'policyStatus',
  policyTemplate: 'policyTemplate',
  policyTemplateAndroid: 'policyTemplateAndroid',
  policyTemplateIos: 'policyTemplateIos',
  policyTemplateLinux: 'policyTemplateLinux',
  policyTemplateMac: 'policyTemplateMac',
  policyTemplateWindows: 'policyTemplateWindows',
  pricing: 'pricing',
  providers: 'providers',
  pushMfaUserEndpoints: 'pushMfaUserEndpoints',
  radiusServer: 'radius_server',
  reports: 'reports',
  routingPolicy: 'routing-policy',
  rule: 'rule',
  ruleTemplates: 'ruleTemplate',
  samba: 'samba',
  scimUserImport: 'scimUserImport',
  settings: 'settings',
  settingsGeneral: 'settingsGeneral',
  settingsHome: 'settingsHome',
  settingsHomeV2: 'settingsHomeV2',
  settingsMfa: 'settingsMfa',
  settingsSecurity: 'settingsSecurity',
  sharedFolder: 'sharedFolder',
  softwareApps: 'software_app',
  softwareAppsStatus: 'software_app_status',
  softwareAppsWindows: 'softwareAppsWindows',
  sso: 'sso',
  ssoApplications: 'ssoApplications',
  ssoTemplate: 'sso_template',
  ssoTemplateV2: 'ssoTemplateV2',
  ssoV2: 'ssoV2',
  suggestedSystemUser: 'suggestedSystemUser',
  system: 'system',
  systemGroup: 'system_group',
  systemInsights: 'systemInsights',
  systemNeedsAttention: 'systemNeedsAttention',
  systemPolicyStatus: 'systemPolicyStatus',
  systemUnacknowledged: 'systemUnacknowledged',
  systemUnenrolled: 'systemUnenrolled',
  systemUser: 'systemUser',
  systemUserWebAuthnKeys: 'systemUserWebAuthnKeys',
  user: 'user',
  userDisplayPreferences: 'userDisplayPreferences',
  userGroup: 'user_group',
  viewAdminsMfaNotRequired: 'viewAdminsMfaNotRequired',
  viewDeviceInvestigationInactiveMetric: 'viewDeviceInvestigationInactiveMetric',
  viewDeviceInvestigationUptimeMetric: 'viewDeviceInvestigationUptimeMetric',
  viewExpiredPassword: 'viewExpiredPassword',
  viewNewUsers: 'viewNewUsers',
  viewReportsReadyForDownload: 'viewreportsReadyForDownload',
  viewScheduledImportErrors: 'viewScheduledImportErrors',
  viewScheduledUserActivations: 'viewScheduledUserActivations',
  viewScheduledUserSuspensions: 'viewScheduledUserSuspensions',
  viewUnencryptedDevices: 'viewUnencryptedDevices',
  viewUpcomingPasswordExpiration: 'viewUpcomingPasswordExpiration',
  viewUserLockout: 'viewUserLockout',
  vppApps: 'vppApps',
  workday: 'workday',
  workdayImportedUser: 'workdayImportedUser',
  workdayWorker: 'workdayWorker',
};

const ItemTypeLabels = /* @alphabetize */ {
  [ItemTypeConsts.activeDirectory]: 'active directory',
  [ItemTypeConsts.application]: 'application',
  [ItemTypeConsts.applicationTemplate]: 'application template',
  [ItemTypeConsts.authNPolicies]: 'authN Policies',
  [ItemTypeConsts.authNPoliciesAside]: 'Conditional Policies Aside',
  [ItemTypeConsts.bookmark]: 'bookmark',
  [ItemTypeConsts.browserPatchManagement]: 'policy',
  [ItemTypeConsts.certificates]: 'certificates',
  [ItemTypeConsts.command]: 'command',
  [ItemTypeConsts.commandTemplateLinux]: 'commandTemplateLinux',
  [ItemTypeConsts.commandTemplateMacOs]: 'commandTemplateMacOs',
  [ItemTypeConsts.commandTemplateWindows]: 'commandTemplateWindows',
  [ItemTypeConsts.configuration]: 'policy',
  [ItemTypeConsts.configurationGroup]: 'policy group',
  [ItemTypeConsts.customPolicyGroupTemplates]: 'custom policy group templates',
  [ItemTypeConsts.defaultPolicyGroupTemplates]: 'default policy group templates',
  [ItemTypeConsts.device]: 'device',
  [ItemTypeConsts.deviceGroup]: 'device group',
  [ItemTypeConsts.dgSystemsReview]: 'dgSystemsReview',
  [ItemTypeConsts.dgUsersReview]: 'dgUsersReview',
  [ItemTypeConsts.directory]: 'directory',
  [ItemTypeConsts.group]: 'group',
  [ItemTypeConsts.insightsEvent]: 'insights event',
  [ItemTypeConsts.ipList]: 'ip list',
  [ItemTypeConsts.ldap]: 'ldap server',
  [ItemTypeConsts.office365]: 'M365/Azure AD',
  [ItemTypeConsts.organizations]: 'organizations',
  [ItemTypeConsts.osPatchManagement]: 'policy',
  [ItemTypeConsts.payment]: 'payment',
  [ItemTypeConsts.policy]: 'policy',
  [ItemTypeConsts.policyGroupTemplate]: 'policy group template',
  [ItemTypeConsts.policyStatus]: 'policy status',
  [ItemTypeConsts.policyTemplate]: 'policy template',
  [ItemTypeConsts.providers]: 'providers',
  [ItemTypeConsts.radiusServer]: 'radius server',
  [ItemTypeConsts.settings]: 'settings',
  [ItemTypeConsts.settingsGeneral]: 'settings',
  [ItemTypeConsts.settingsSecurity]: 'user security settings',
  [ItemTypeConsts.sharedFolder]: 'shared folder',
  [ItemTypeConsts.softwareApps]: 'managed software',
  [ItemTypeConsts.suggestedSystemUser]: 'user',
  [ItemTypeConsts.system]: 'system',
  [ItemTypeConsts.systemGroup]: 'system group',
  [ItemTypeConsts.systemInsights]: 'system insights',
  [ItemTypeConsts.systemUser]: 'user',
  [ItemTypeConsts.user]: 'user',
  [ItemTypeConsts.userGroup]: 'user group',
};

const ItemTypeDisplayNames = /* @alphabetize */ {
  [DirectoryGroupingConstants.memberTypes.user]: 'User',
  [ItemTypeConsts.activeDirectory]: 'Active Directory',
  [ItemTypeConsts.adBridgeAgent]: 'Domain Agent',
  [ItemTypeConsts.appAuthorization]: 'MacOS Keychain Application Access',
  [ItemTypeConsts.application]: 'Application',
  [ItemTypeConsts.applicationTemplate]: 'Application Template',
  [ItemTypeConsts.applications]: 'Applications',
  [ItemTypeConsts.authNPolicies]: 'Policy',
  [ItemTypeConsts.bambooHR]: 'Bamboo HR',
  [ItemTypeConsts.bookmark]: 'Bookmark',
  [ItemTypeConsts.browserPatchManagement]: 'Browser Patch Policy',
  [ItemTypeConsts.certificates]: 'Certificates',
  [ItemTypeConsts.cloudDirectory]: 'Cloud Directory',
  [ItemTypeConsts.command]: 'Command',
  [ItemTypeConsts.commandQueues]: 'Command Queues',
  [ItemTypeConsts.commandResult]: 'Command',
  [ItemTypeConsts.commandRunner]: 'Command Runner',
  [ItemTypeConsts.commandTemplate]: 'Command Template',
  [ItemTypeConsts.commandTemplateLinux]: 'Command Template Linux',
  [ItemTypeConsts.commandTemplateMacOs]: 'Command Template MacOs',
  [ItemTypeConsts.commandTemplateWindows]: 'Command Template Windows',
  [ItemTypeConsts.configuration]: 'Policy',
  [ItemTypeConsts.configurationGroup]: 'Policy Group',
  [ItemTypeConsts.customPolicyGroupTemplates]: 'Custom Policy Group Templates',
  [ItemTypeConsts.defaultPolicyGroupTemplates]: 'Default Policy Group Templates',
  [ItemTypeConsts.device]: 'Device',
  [ItemTypeConsts.deviceGroup]: 'Device Group',
  [ItemTypeConsts.devices]: 'Devices',
  [ItemTypeConsts.directories]: 'Directories',
  [ItemTypeConsts.directory]: 'Directory',
  [ItemTypeConsts.gSuite]: 'Google Workspace',
  [ItemTypeConsts.group]: 'Group',
  [ItemTypeConsts.home]: 'Home',
  [ItemTypeConsts.hrDirectory]: 'HR Directory',
  [ItemTypeConsts.idsource]: 'Agent',
  [ItemTypeConsts.importUsers]: 'Import Users',
  [ItemTypeConsts.insightsEvent]: 'InsightsEvent',
  [ItemTypeConsts.ipList]: 'IP List',
  [ItemTypeConsts.ldap]: 'LDAP',
  [ItemTypeConsts.office365]: 'M365/Entra ID',
  [ItemTypeConsts.okta]: 'Okta',
  [ItemTypeConsts.organizations]: 'Organization',
  [ItemTypeConsts.osPatchManagement]: 'OS Patch Policy',
  [ItemTypeConsts.passwordManager]: 'Password Manager',
  [ItemTypeConsts.patchManagement]: 'Patch Policy',
  [ItemTypeConsts.policy]: 'Policy',
  [ItemTypeConsts.policyGroupTemplate]: 'Policy Group Template',
  [ItemTypeConsts.policyGroupTemplateEdit]: 'Edit Policy Group Template',
  [ItemTypeConsts.policyStatus]: 'Policy Status',
  [ItemTypeConsts.providers]: 'Provider',
  [ItemTypeConsts.radiusServer]: 'RADIUS Server',
  [ItemTypeConsts.reports]: 'Reports',
  [ItemTypeConsts.settings]: 'Organization Settings',
  [ItemTypeConsts.settingsGeneral]: 'Settings',
  [ItemTypeConsts.settingsSecurity]: 'User Security Settings',
  [ItemTypeConsts.sharedFolder]: 'Shared Folder',
  [ItemTypeConsts.softwareApps]: 'Managed Software',
  [ItemTypeConsts.sso]: 'SSO',
  [ItemTypeConsts.ssoApplications]: 'SSO Applications',
  [ItemTypeConsts.ssoTemplate]: 'SSO Template',
  [ItemTypeConsts.system]: 'System',
  [ItemTypeConsts.systemGroup]: 'System Group',
  [ItemTypeConsts.systemUser]: 'User',
  [ItemTypeConsts.user]: 'Administrator',
  [ItemTypeConsts.userGroup]: 'User Group',
  [ItemTypeConsts.viewAdminsMfaNotRequired]: 'Admins Without MFA Required',
  [ItemTypeConsts.viewDeviceInvestigationInactiveMetric]: 'Devices inactive greater than 7 days',
  [ItemTypeConsts.viewDeviceInvestigationUptimeMetric]: 'Devices uptime greater than X days',
  [ItemTypeConsts.viewExpiredPassword]: 'Expired Password',
  [ItemTypeConsts.viewNewUsers]: 'New Users (Past 7 days)',
  [ItemTypeConsts.viewReportsReadyForDownload]: 'Reports Ready for Download',
  [ItemTypeConsts.viewScheduledImportErrors]: 'Scheduled Import User Errors (Past 24 Hours)',
  [ItemTypeConsts.viewScheduledUserActivations]: 'Scheduled User Activations',
  [ItemTypeConsts.viewScheduledUserSuspensions]: 'Scheduled User Suspensions',
  [ItemTypeConsts.viewUnencryptedDevices]: 'Unencrypted Devices',
  [ItemTypeConsts.viewUpcomingPasswordExpiration]: 'Upcoming Password Expiration',
  [ItemTypeConsts.viewUserLockout]: 'User Lockouts',
  [ItemTypeConsts.workday]: 'Workday',
};

/**
 * @deprecated Prefer hardcoded routes.
 *
 * Going forward, we hard-coded routes rather than reference this const, for
 * the following reasons:
 *
 * - Changing a route is unlikely and not as simple as changing a constant.
 * - When breaking applications out of the monolith, code can no longer reference this const.
 * - Sharing constants like this across the whole monorepo causes deployment issues.
 */
const ItemTypeRoutes = /* @alphabetize */ {
  [ItemTypeConsts.account]: '#/account',
  [ItemTypeConsts.accountBilling]: '#/account/payment',
  [ItemTypeConsts.accountGeneral]: '#/account/general',
  [ItemTypeConsts.accountInvoices]: '#/account/invoices',
  [ItemTypeConsts.accountUpgrade]: '#/account/upgrade',
  [ItemTypeConsts.activeDirectory]: '#/directories/active-directory',
  [ItemTypeConsts.alerts]: '#/alerts',
  [ItemTypeConsts.appAuthorization]: '#/security/authnpolicies/settings/trustedApp',
  [ItemTypeConsts.application]: '#/applications',
  [ItemTypeConsts.applicationTemplate]: '#/applications',
  [ItemTypeConsts.authNPolicies]: '#/security/authnpolicies',
  [ItemTypeConsts.authNPoliciesSettings]: '#/security/authnpolicies/settings',
  [ItemTypeConsts.bookmark]: '#/applications',
  [ItemTypeConsts.browserPatchManagement]: '#/configurations/patch/browser',
  [ItemTypeConsts.certificates]: '#/certificates',
  [ItemTypeConsts.cloudDirectory]: '#/directories/cloud-directories',
  [ItemTypeConsts.command]: '#/commands',
  [ItemTypeConsts.commandQueue]: '#/commands',
  [ItemTypeConsts.commandQueues]: '#/commands/commandqueues',
  [ItemTypeConsts.commandResult]: '#/commands',
  [ItemTypeConsts.commandResults]: '#/commands/commandResults',
  [ItemTypeConsts.commandTemplate]: '#/commands',
  [ItemTypeConsts.commandTemplateEdit]: '#/commands/managetemplates',
  [ItemTypeConsts.commandTemplateLinux]: '#/commands',
  [ItemTypeConsts.commandTemplateMacOs]: '#/commands',
  [ItemTypeConsts.commandTemplateWindows]: '#/commands',
  [ItemTypeConsts.configuration]: '#/configurations',
  [ItemTypeConsts.configurationGroup]: '#/groups/configuration',
  [ItemTypeConsts.customPolicyGroupTemplates]: '#/groups/configuration/grouptemplates',
  [ItemTypeConsts.defaultPolicyGroupTemplates]: '#/groups/configuration/grouptemplates',
  [ItemTypeConsts.device]: '#/devices',
  [ItemTypeConsts.deviceGroup]: '#/groups/device',
  [ItemTypeConsts.deviceList]: '#/devices/list',
  [ItemTypeConsts.deviceOverview]: '#/devices/overview',
  [ItemTypeConsts.directory]: '#/directories',
  [ItemTypeConsts.gSuite]: '#/directories/cloud-directories',
  [ItemTypeConsts.group]: '#/groups',
  [ItemTypeConsts.home]: '#/home',
  [ItemTypeConsts.hrDirectory]: '#/directories/hr-directories',
  [ItemTypeConsts.identityProviders]: '#/directories/identity-providers',
  [ItemTypeConsts.ipList]: '#/security/iplists',
  [ItemTypeConsts.ldap]: '#/ldap',
  [ItemTypeConsts.mdmSettings]: '#/mdm',
  [ItemTypeConsts.office365]: '#/directories/cloud-directories',
  [ItemTypeConsts.osManagement]: '#/osmanagement',
  [ItemTypeConsts.osPatchManagement]: '#/configurations/patch/os',
  [ItemTypeConsts.passwordManager]: '#/passwordmanager',
  [ItemTypeConsts.patchManagement]: '#/configurations/patch',
  [ItemTypeConsts.policy]: '#/configurations',
  [ItemTypeConsts.policyGroupTemplate]: '#/groups/configuration/grouptemplates',
  [ItemTypeConsts.policyGroupTemplateEdit]: '#/groups/configuration/managetemplates',
  [ItemTypeConsts.policyTemplate]: '#/configurations',
  [ItemTypeConsts.pricing]: '#/pricing',
  [ItemTypeConsts.radiusServer]: '#/radius',
  [ItemTypeConsts.reports]: '#/reports',
  [ItemTypeConsts.settingHome2Column]: '#/home/settings2column',
  [ItemTypeConsts.settings]: '#/settings',
  [ItemTypeConsts.settingsGeneral]: '#/settings/general',
  [ItemTypeConsts.settingsHome]: '#/home/settings',
  [ItemTypeConsts.settingsMfa]: '#/settings/mfa',
  [ItemTypeConsts.settingsSecurity]: '#/settings/security',
  [ItemTypeConsts.softwareApps]: '#/managedSoftware',
  [ItemTypeConsts.softwareAppsWindows]: '#/managedSoftware/windows',
  [ItemTypeConsts.sso]: '#/applications',
  [ItemTypeConsts.ssoApplications]: '#/applications',
  [ItemTypeConsts.ssoTemplate]: '#/applications',
  [ItemTypeConsts.system]: '#/devices',
  [ItemTypeConsts.systemGroup]: '#/groups/device',
  [ItemTypeConsts.systemUser]: '#/users',
  [ItemTypeConsts.userGroup]: '#/groups/user',
  [ItemTypeConsts.viewAdminsMfaNotRequired]: '#/home/viewall/adminsMfaNotRequired',
  [ItemTypeConsts.viewDeviceInvestigationInactiveMetric]: '#/home/deviceinvestigation',
  [ItemTypeConsts.viewDeviceInvestigationUptimeMetric]: '#/home/viewall/devices/uptimeMetric',
  [ItemTypeConsts.viewExpiredPassword]: '#/home/viewall/expiredPasswords',
  [ItemTypeConsts.viewNewUsers]: '#/home/viewall/newUsers',
  [ItemTypeConsts.viewReportsReadyForDownload]: '#/home/viewall/others/reportsReadyForDownload',
  [ItemTypeConsts.viewScheduledImportErrors]: '#/home/viewall/users/scheduledImportErrors',
  [ItemTypeConsts.viewScheduledUserActivations]: '#/home/viewall/users/ScheduledUserActivations',
  [ItemTypeConsts.viewScheduledUserSuspensions]: '#/home/viewall/users/ScheduledUserSuspensions',
  [ItemTypeConsts.viewUnencryptedDevices]: '#/home/viewall/unencryptedDevices',
  [ItemTypeConsts.viewUpcomingPasswordExpiration]: '#/home/viewall/upcomingPasswordExpiration',
  [ItemTypeConsts.viewUserLockout]: '#/home/viewall/userLockouts',
  [ItemTypeConsts.workday]: '#/directories/hr-directories',
  default: '/#',
};

const ItemTypeAPIs = /* @alphabetize */ {
  [ItemTypeConsts.application]: 'applications',
  [ItemTypeConsts.directory]: 'directories',
  [ItemTypeConsts.radiusServer]: 'radiusservers',
  [ItemTypeConsts.userGroup]: 'usergroups',
};

const getDetailsRoute = (detailsAsideItemType, item) => {
  const {
    activeDirectory,
    apple,
    configuration,
    configurationGroup,
    cloudDirectory,
    device,
    deviceAppStatus,
    deviceCustomAppStatus,
    deviceCustomSelfHostedAppStatus,
    deviceGroup,
    directory,
    google,
    group,
    gSuite,
    hrDirectory,
    ldap,
    office365,
    policy,
    softwareApps,
    sso,
    systemGroup,
    systemUser,
    user,
    userGroup,
    workday,
    workdayImportedUser,
  } = ItemTypeConsts;

  const { id } = item;
  const details = 'details';

  // If the `detailsAsideItemType` is a directory, then `item.type` actually
  // contains the type for redirecting
  const type = detailsAsideItemType === directory ? item.type : detailsAsideItemType;

  // This method works on PoliciesItemTable on devices page to know if it's an OSPM policy or not
  const isOsPatchManagementPolicy = () => {
    const curTemplate = item.template ? item.template.name : '';
    return typeof ospPolicyNameToOs[curTemplate] !== typeof undefined;
  };

  // This method works on PoliciesItemTable on devices page to know if it's an BPM policy or not
  const isBrowerPatchManagementPolicy = () => {
    const curTemplate = item.template ? item.template.name : '';
    return typeof browserPolicyNameToBrowser[curTemplate] !== typeof undefined;
  };

  let parentOS = apple;
  if (detailsAsideItemType === deviceAppStatus) {
    if (item.settings[0].packageManager === 'GOOGLE_ANDROID') {
      parentOS = google;
    }
  }

  const detailsRouteMap = new Map([
    [activeDirectory, `${ItemTypeRoutes[activeDirectory]}/${id}/${details}`],
    [configurationGroup, `${ItemTypeRoutes[group]}/${configuration}/${id}/${details}`],
    [device, `${ItemTypeRoutes[device]}/${id}/${details}`],
    [deviceAppStatus, `${ItemTypeRoutes[softwareApps]}/${parentOS}/${id}/${details}`],
    [deviceCustomAppStatus, `${ItemTypeRoutes[softwareApps]}/${parentOS}/customApp/${id}/${details}`],
    [deviceCustomSelfHostedAppStatus, `${ItemTypeRoutes[softwareApps]}/custom/${id}/${details}`],
    [deviceGroup, `${ItemTypeRoutes[group]}/${device}/${id}/${details}`],
    [gSuite, `${ItemTypeRoutes[cloudDirectory]}/${gSuite}/${id}/${details}`],
    [ldap, `${ItemTypeRoutes[ldap]}/${ldap}/${id}/${details}`],
    [office365, `${ItemTypeRoutes[cloudDirectory]}/${office365}/${id}/${details}`],
    [policy, `${ItemTypeRoutes[configuration]}/${id}/${details}`],
    [sso, `${ItemTypeRoutes[sso]}/${id}/${details}`],
    [systemGroup, `${ItemTypeRoutes[group]}/${device}/${id}/${details}`],
    [systemUser, `${ItemTypeRoutes[systemUser]}/${id}/${details}`],
    [userGroup, `${ItemTypeRoutes[group]}/${user}/${id}/${details}`],
    [workdayImportedUser, `${ItemTypeRoutes[systemUser]}/${id}/${details}`],
    [workday, `${ItemTypeRoutes[hrDirectory]}/${workday}/${id}/${details}`,
    ],
  ]);

  if (item.settings) {
    const storedPackageObject = item.settings[0].storedPackage || item.settings[0].storedPackageObjectId || '';
    const auxPackageManager = item.settings[0].packageManager;
    if (auxPackageManager === 'APPLE_CUSTOM') {
      return storedPackageObject ? detailsRouteMap.get(deviceCustomAppStatus)
        : detailsRouteMap.get(deviceCustomSelfHostedAppStatus);
    }
  }

  if (type === 'policy' && isOsPatchManagementPolicy()) {
    return `${detailsRouteMap.get(type)}/0/1/0`;
  }
  if (type === 'policy' && isBrowerPatchManagementPolicy()) {
    return `${detailsRouteMap.get(type)}/0/1/1`;
  }
  return detailsRouteMap.get(type);
};

const getStatusRoute = (item) => {
  const {
    softwareApps,
  } = ItemTypeConsts;
  const { id } = item;
  let type = '';
  const { packageManager } = item.settings[0];

  if (packageManager === 'CHOCOLATEY') {
    return `${ItemTypeRoutes[softwareApps]}/windows/${id}/status`;
  }

  if (packageManager === 'WINDOWS_MDM') {
    type = 'customApp';
  }

  if (packageManager === 'MICROSOFT_STORE') {
    type = 'microsoftStore';
  }

  if (packageManager === 'WINGET') {
    type = 'appCatalog';
  }

  return `${ItemTypeRoutes[softwareApps]}/windows/${type}/${id}/status`;
};

export default {
  ItemTypeAPIs,
  ItemTypeConsts,
  ItemTypeDisplayNames,
  ItemTypeLabels,
  ItemTypeRoutes,
  getDetailsRoute,
  getStatusRoute,
};
