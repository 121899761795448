import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';
import MdmUnenrolledDevicesBannerSlot from '@/components/ViewportBanner/MdmUnenrolledDevicesBannerSlot.vue';

const mdmUnenrolledDevicesBannerDismissed = '2025-01-21-jcMdmUnenrolledDevicesBannerDismissed';

const MdmUnenrolledDevicesBannerConfig = () => ({
  name: 'MdmUnenrolledDevicesBannerConfig',
  bannerType: ViewportBannerConsts.types.error,
  childComponent: MdmUnenrolledDevicesBannerSlot,
  localStorageKey: mdmUnenrolledDevicesBannerDismissed,
  bannerVisibility: () => true,
});

export default MdmUnenrolledDevicesBannerConfig;
