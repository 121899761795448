import { BannerType } from '@jumpcloud-ap/banner-controller';
import AdvancedCertificationBannerConfig from '@/components/ViewportBanner/AdvancedCertificationBannerConfig';
import ClosedCustomerNeedsCCConfig from '@/components/ViewportBanner/ClosedCustomerNeedsCCConfig';
import MdmUnenrolledDevicesBannerConfig from '@/components/ViewportBanner/MdmUnenrolledDevicesBannerConfig';
import MspTrialBannerConfig from '@/components/ViewportBanner/MspTrialBannerConfig';
import NewlySelfServedBannerConfig from '@/components/ViewportBanner/NewlySelfServedBannerConfig';
import PastDueAccountFirstWarningBannerConfig from '@/components/ViewportBanner/PastDueAccountFirstWarningBannerConfig';
import PastDueAccountSecondWarningBannerConfig from '@/components/ViewportBanner/PastDueAccountSecondWarningBannerConfig';

/**
 * Create Banners and config in a module and import it
 *
 * @example Banner Configuration
 * const newBannerConfig = ({isCommandRunner, isAnMsp }) => {
 *  name: 'bannerName', //Unique Name
 *  bannerType: ViewportBannerConsts.types.informational, // Will default to info (blue)
 *  bannerText: 'Banner text', // Main text
 *  learnMoreLink: 'https://', // Link
 *  learnMoreText: 'Take this 5 minute survey.', // Link Text
 *  localStorageKey: localStorageConst, // Import Local Storage Const
 *  startDate: '2020-06-01', // Start Date (inclusive)
 *  endDate: '2020-06-07', // End Date (inclusive)
 *
 *  Note: For the dates use `/` separators so that the date will work in Safari
 *  @see https://stackoverflow.com/questions/4310953/invalid-date-in-safari
 *
 *  Show Banner for all admins:
 *  @example bannerVisibility: () => true,
 *
 *  Show Banner for admins that aren't MSP or Command Runner:
 *  @example bannerVisibility: () => !isAnMsp && !isCommandRunner,
 *
 *  Show Banner for admins (including MSP):
 *  @example bannerVisibility: () => !isCommandRunner,
 *
 *  Show Banner for Customers with > 10 systems:
 *  @example bannerVisibility: async () => {
 *    const numOfSystems = await api.getSystems();
 *    return isCustomer && numOfSystems > 10;
 *   },
 * }
 *
 * bannerVisibility can include any logic that is required for a banner.
 * Previous banners have been based on org created date,
 * number of resources addded, customer status,
 * experiment audience, etc.
 *
 * Can find common examples used in unit tests at
 * @/components/ViewportBanner/__tests__/TestBanners.js
 *
 * @return {bannerVisibility} returns a value of true or false.
*/

/**
 * To set dates overrides for a particular banner for QA
 * window.localStorage.setItem(
  'bannerName',
  JSON.stringify({ startDate: '2021-08-04', endDate: '2021-08-12' }),
);
 */

/**
 * Create a new banner for export.
 * Banners can be passed parameters for visibility logic.
 * For example, pass userTypes types for checking MSP and Command Runner
 * statuses for banners that need to be hidden from certain user types
 * @example export const newBanner = user => new Banner(newBannerConfig(user));
 */

/**
 * @param {Object} user User meta-data passed from ViewportBanner.vue
 *  {
 *    isCommandRunner: false,
 *    isCustomer: false,
 *    isAnMsp: false,
 *    orgCreatedDate: '2020-03-23'
 *  }
 * @returns {Array} Array of banners
 */

/**
 * @param {Object} provider Provider meta-data passed from ViewportBanner.vue
 *  {
 *    disallowOrgCreation: false,
 *  }
 * @returns {Array} Array of banners
 */

/**
 * @param {Boolean} hasUnenrolledDevices Provider meta-data passed from ViewportBanner.vue
 *  indicate if the org has mdm unenrolled devices
 * @returns {Array} Array of banners
 */
export default function ({ user, provider, hasUnenrolledDevices }) {
  // Past due banners supercede all other existing banners
  const firstWarningBannerConfig = PastDueAccountFirstWarningBannerConfig(user);
  if (firstWarningBannerConfig.bannerVisibility()) {
    return [
      new BannerType(firstWarningBannerConfig),
    ];
  }
  const secondWarningBannerConfig = PastDueAccountSecondWarningBannerConfig(user);
  if (secondWarningBannerConfig.bannerVisibility()) {
    return [
      new BannerType(secondWarningBannerConfig),
    ];
  }
  const closedCustomerNeedsCCConfig = ClosedCustomerNeedsCCConfig(user);
  if (closedCustomerNeedsCCConfig.bannerVisibility()) {
    return [
      new BannerType(closedCustomerNeedsCCConfig),
    ];
  }
  if (provider.disallowOrgCreation) {
    return [
      new BannerType(MspTrialBannerConfig(user, provider)),
    ];
  }

  const auxBanners = [
    // The survey banner is displayed on 6/20/2022 and removed on 6/24/2022
    new BannerType(NewlySelfServedBannerConfig(user)),
    new BannerType(AdvancedCertificationBannerConfig()),
  ];

  if (hasUnenrolledDevices) {
    auxBanners.push(new BannerType(MdmUnenrolledDevicesBannerConfig()));
  }

  return auxBanners;
}
