<template>
  <section
    id="viewport-banner"
    class="banner"
  >
    <BannerController
      v-for="banner in filteredBanners"
      :key="banner.name"
      :bannerContent="banner"
      @click="() => trackBannerClick(banner)"
    />
  </section>
</template>

<script>
import { BannerController } from '@jumpcloud-ap/banner-controller';
import { DateUtil } from '@jumpcloud-ap/date-util';
import { PaymentHelper } from '@jumpcloud-ap/payments';
import { UiEvents } from '@jumpcloud/ui-events-vue';
import { ViewportBannerConsts } from '@/components/ViewportBanner/constants/ViewportBannerConsts';
import { accountsReceivable, accountsReceivableOld } from '@/components/ViewportBanner/constants/AccountConstants';
import { mapGetters } from 'vuex';
import CreateBanners from '@/components/ViewportBanner/CreateBanners';
import DevicesApi from '@/api/DevicesApi';
import OrganizationsApi from '@/api/OrganizationsApi';
import ProvidersApi from '@/api/ProvidersApi';

const {
  closedCustomerNeedsCCBanner,
  pastDueAccountFirstWarningBanner,
  pastDueAccountSecondWarningBanner,
} = ViewportBannerConsts.names;

export default {
  name: 'ViewportBanner',

  components: {
    BannerController,
  },

  data() {
    return {
      accountsReceivable: accountsReceivable.NONE,
      banners: [],
      orgCreatedDate: '',
    };
  },

  computed: {
    ...mapGetters('CoreUserInfoModule', [
      'isCommandRunner',
      'isCustomer',
      'orgId',
      'providerId',
    ]),

    filteredBanners() {
      let applicableBanners = [];
      if (this.shouldShowForAdmin) {
        // only show newlySelfServedBanner if in showBanner list
        applicableBanners = this.banners.filter(banner => banner.showBanner && banner.name === 'newlySelfServedBanner');
        applicableBanners = !applicableBanners.length
          ? this.banners.filter(banner => banner.showBanner) : applicableBanners;
      }

      return applicableBanners;
    },

    shouldShowForAdmin() {
      // show for all admins if org created more than 1 day ago
      return DateUtil.moreThanDaysBeforeToday(this.orgCreatedDate, 1);
    },
  },

  watch: {
    accountsReceivable(newValue) {
      const pastDueBannerShown = this.banners.some(banner => banner.showBanner
        && (banner.name === pastDueAccountSecondWarningBanner
        || banner.name === pastDueAccountFirstWarningBanner));

      if ((
        // TODO: PU-841 cleanup after migration to new constants is done
        newValue !== accountsReceivableOld.BANNER
        // TODO: PU-841 cleanup after migration to new constants is done
        || newValue !== accountsReceivableOld.WARNING
        || newValue !== accountsReceivable.FIRST_WARNING
        || newValue !== accountsReceivable.SECOND_WARNING
      )
        && pastDueBannerShown) {
        // refresh banners if either past due account banner is visible
        this.createBanners();
      }
    },

    isCustomer() {
      const closedCustomerBannerShown = this.banners.some(banner => banner.showBanner
        && banner.name === closedCustomerNeedsCCBanner);
      if (closedCustomerBannerShown) this.createBanners();
    },
  },

  created() {
    this.handleCreated();
  },

  beforeDestroy() {
    PaymentHelper.unsubscribeFromPaymentUpdate(this.fetchOrgDetails);
  },

  methods: {
    async createBanners() {
      const user = this.getUserConfiguration();
      const provider = await this.getProviderConfiguration();
      const hasUnenrolledDevices = await DevicesApi.hasUnenrolledDevices();
      this.banners = CreateBanners({ user, provider, hasUnenrolledDevices });
    },

    async fetchOrgDetails() {
      const orgDetails = await OrganizationsApi.getOrganizationDetails(this.orgId);
      this.accountsReceivable = orgDetails.accountsReceivable;
      this.orgCreatedDate = orgDetails.created;
    },

    async getProviderConfiguration() {
      let disallowOrgCreation = false;

      // logic copied from SettingsModule->getProviderDetails
      if (!this.isCommandRunner && this.providerId) {
        const providerDetails = await ProvidersApi.getProviderDetails(this.providerId);
        disallowOrgCreation = providerDetails.disallowOrgCreation;
      }

      return { disallowOrgCreation };
    },

    getUserConfiguration() {
      return {
        accountsReceivable: this.accountsReceivable,
        isCommandRunner: this.isCommandRunner,
        isAnMsp: !!this.providerId,
        isCustomer: this.isCustomer,
        orgCreatedDate: this.orgCreatedDate,
        provider: this.providerId,
      };
    },

    async handleCreated() {
      await this.fetchOrgDetails();
      await this.createBanners();
      PaymentHelper.subscribeToPaymentUpdate(this.fetchOrgDetails);
    },

    trackBannerClick(banner) {
      const { learnMoreLink, learnMoreText, name } = banner;

      UiEvents.triggerLinkClicked({
        description: `Banner Clicked: ${name}`,
        page: this.$page,
        section: 'banner',
        text: learnMoreText,
        toUrl: learnMoreLink,
      });
    },
  },
};
</script>

<style module>
.container {
  position: relative;
}
</style>
