function getSpecialCharacterEscapedString(exp: string) {
  const specialCharactersRegex = /[-\\^$*+?.()|[\]{}]/g;
  const slashCharacterRegex = /[/]/g;

  return exp.replace(specialCharactersRegex, '\\$&').replace(slashCharacterRegex, '/$&');
}

interface FieldDetails {
  name: string;
  isObjectId: boolean;
}

/**
 * Create the array of or criteria to filter on, one for each field and word in the search
 * term. And object with isObjectId: false is treated specially and uses a $eq matcher only
 * if the term is an object ID. e.g.:
 *
 * "email:$regex:/Term1/i",
 * "email:$regex:/Term2/i",
 * "firstname:$regex:/Term1/i",
 * "firstname:$regex:Term2/i",
 * "_id:$eq:5e4f4b3b1f6f6d001f6f6d00"
 */
export function getOrSearchCriteria(searchTerm: string, fields: FieldDetails[]) {
  const fixedSearchTerm = getSpecialCharacterEscapedString(searchTerm);
  const words = fixedSearchTerm.split(' ').filter(word => !!word);

  const objectIdRegex = /^[0-9a-fA-F]{24}$/;

  const criteria = fields.flatMap(field => words.map(word => {
    if (field.isObjectId) {
      return objectIdRegex.test(word) ? `${field.name}:$eq:${word}` : '';
    }
    return `${field.name}:$regex:/${word}/i`;
  })).filter(str => !!str);

  return criteria;
}
