import { GoToResult } from './GoToResult';
import iconDevices from '@/img/icons/nav/icon-nav-devices.svg';
import iconUsers from '@/img/icons/nav/icon-nav-users.svg';
import systemsApi from '@/api/SystemApi';
import usersApi from '@/api/SystemUserApi';
import type { GoToResultRecentKey } from './GoToResult';
import type { SystemResponse } from '@/api/SystemApi';
import type { UserResponse } from '@/api/SystemUserApi';

const keyTypeUser = 'resource:user';
const keyTypeDevice = 'resource:device';

class ResourcesSection {
  public lookup(recentKey: GoToResultRecentKey) : Promise<GoToResult | undefined> | undefined {
    switch (recentKey.type) {
      case keyTypeUser:
        return usersApi.get(recentKey.id)
          .then(user => this.buildUserResult(user))
          .catch(() => undefined);
      case keyTypeDevice:
        return systemsApi.get(recentKey.id)
          .then(system => this.buildSystemResult(system))
          .catch(() => undefined);
      default:
        return undefined;
    }
  }

  public async updateSearchTerm(searchTerm: string): Promise<GoToResult[]> {
    if (searchTerm === '') {
      return [];
    }

    // Call the SystemUser and System APIs in parallel
    const [users, systems] = await Promise.all([
      usersApi.search(searchTerm).then(
        data => data.results.map((user) => this.buildUserResult(user)),
      ).catch(() => [] as GoToResult[]),
      systemsApi.search(searchTerm).then(
        data => data.results.map((system) => this.buildSystemResult(system)),
      ).catch(() => [] as GoToResult[]),
    ]);

    // Give each entry a unique id
    return users.concat(systems).map((item, index) => ({
      ...item,
      id: index,
    }));
  }

  private buildUserResult(user: UserResponse) : GoToResult {
    return new GoToResult({
      title: this.formattedUserTitle(user),
      subtitle: 'User',
      imageComponent: iconUsers,
      url: `#/users/${user._id}/details`,
      recentKey: { type: keyTypeUser, id: user._id },
    });
  }

  private buildSystemResult(system: SystemResponse) : GoToResult {
    return new GoToResult({
      title: system.displayName ? system.displayName : system.hostname,
      subtitle: 'Device',
      imageComponent: iconDevices,
      url: `#/devices/${system._id}/details`,
      recentKey: { type: keyTypeDevice, id: system._id },
    });
  }

  private formattedUserTitle(user: UserResponse) : string {
    // If they don't have a first AND last name, just email
    // We can make this better over time if needed
    return (user.lastname && user.firstname)
      ? `${user.lastname}, ${user.firstname}`
      : user.email;
  }
}

export default new ResourcesSection();
