import { createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export class DevicesApi {
  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async unenrolledDevices() {
    const response = await this.axiosInstance.get('/api/systems?filter[0]=mdm.vendor:$ne:internal&filter[1]=mdm.vendor:$ne:external&filter[2]=osFamily:eq:darwin');
    return response.data.totalCount ? response.data.totalCount : 0;
  }

  async hasUnenrolledDevices() {
    const response = await this.axiosInstance.get('/api/systems?filter[0]=mdm.vendor:$ne:internal&filter[1]=mdm.vendor:$ne:external&filter[2]=osFamily:eq:darwin');
    return response.data.totalCount && response.data.totalCount > 0;
  }
}

export default new DevicesApi();
