import {
  init,
  logAppMount,
  mountApp,
  teardown,
} from './admin-portal';
import { mountStyles, unmountStyles } from 'virtual:mfe-css';

export const bootstrap = async () => {
  await init();
};

export const mount = async () => {
  await mountStyles();
  await mountApp();
  logAppMount();
};

export const unmount = () => {
  unmountStyles();
  teardown();

  return Promise.resolve();
};
