import { createStore } from 'vuex';

import { FeatureFlagsModule } from '../feature-flags';
import { createCoreUserInfoModule } from '@jumpcloud-ap/core-user-info';
import { createRouteInfoModule } from '@jumpcloud-ap/route-info';
import { createTargetedPromoModule } from '@jumpcloud-ap/feature-promos-vue3';
import { setupEntitlementModule, setupTimeBasedTrialModule } from '@jumpcloud-ap/time-based-trial-vue3';
import { setupPlanModule } from '@jumpcloud-ap/plans';
import AlertsStatsModule from './modules/AlertsStatsModule';
import OnboardingModule from './modules/OnboardingModule';
import PartnersModule from './modules/PartnersModule';

const store = createStore({
  modules: {
    AlertsStatsModule,
    CoreUserInfoModule: createCoreUserInfoModule(),
    FeatureFlagsModule,
    OnboardingModule,
    PartnersModule,
    RouteInfoModule: createRouteInfoModule(),
    TargetedPromoModule: createTargetedPromoModule(),
  },
});

setupEntitlementModule(store);
setupPlanModule(store);
setupTimeBasedTrialModule(store);

export {
  store,
};
