import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import { getOrSearchCriteria } from './utils/getOrSearchCriteria';

const searchFields = [
  { name: '_id', isObjectId: true },
  { name: 'amazonInstanceID', isObjectId: false },
  { name: 'displayName', isObjectId: false },
  { name: 'hostname', isObjectId: false },
  { name: 'mdm.internal.deviceId', isObjectId: true },
  { name: 'mdm.internal.windowsDeviceId', isObjectId: true },
  { name: 'serialNumber', isObjectId: false },
];

const defaultParams = {
  limit: 15,
  sort: 'hostname',
  filter: {},
  fields: searchFields.map(field => field.name),
};

export interface SystemResponse {
  _id: string;
  displayName: string;
  hostname: string;
}

export interface SystemSearchResponse {
  totalCount: number;
  results: Array<SystemResponse>;
}

class SystemApi {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async get(id: string) : Promise<SystemResponse> {
    const params = {
      fields: defaultParams.fields.join(' '),
    };

    return this.axiosInstance.get<SystemResponse>(`/api/systems/${id}`, { params })
      .then(resp => resp.data);
  }

  async search(searchTerm: string) : Promise<SystemSearchResponse> {
    const body = {
      ...defaultParams,
      filter: {
        or: getOrSearchCriteria(searchTerm, searchFields),
      },
    };

    return this.axiosInstance.post<SystemSearchResponse>('/api/search/systems', body)
      .then((resp) => (resp && resp.data
        ? resp.data
        : Promise.reject(new Error('Unable to search systems'))));
  }
}

const api = new SystemApi();
export default api;
