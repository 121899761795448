<template>
  <ParagraphBody>
    ❗<strong>{{ unenrolledDevices }}</strong>
    {{ mdmTxt }}
    <Link
      href="#/devices/list/unenrolled"
      text="View Devices"
    /> |
    <Link
      href="https://jumpcloud.com/support/re-enroll-apple-devices-into-mdm-and-preserve-device-record"
      isExternal
      target="_blank"
      text="Re-enrollment Support Page"
    />
  </ParagraphBody>
</template>

<script>
import {
  Link,
  ParagraphBody,
} from '@jumpcloud/ui-components';
import DevicesApi from '@/api/DevicesApi';

export default {
  name: 'MdmUnenrolledDevicesBannerSlot',

  components: {
    Link,
    ParagraphBody,
  },

  data() {
    return {
      unenrolledDevices: 0,
    };
  },

  computed: {
    mdmTxt() {
      return 'macOS devices are not enrolled in JumpCloud MDM or need to be re-enrolled - ';
    },
  },

  async created() {
    this.unenrolledDevices = await DevicesApi.unenrolledDevices();
  },
};
</script>

<style scoped module>
  .externalLink {
    margin-left: var(--jc-spacer);
  }
</style>
