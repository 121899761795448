import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';
import { getOrSearchCriteria } from './utils/getOrSearchCriteria';

const searchFields = [
  { name: '_id', isObjectId: true },
  { name: 'email', isObjectId: false },
  { name: 'firstname', isObjectId: false },
  { name: 'lastname', isObjectId: false },
];

const defaultParams = {
  limit: 5,
  sort: 'lastname',
  filter: {},
  fields: searchFields.map(field => field.name),
};

export interface UserResponse {
  _id: string;
  email: string;
  firstname: string;
  lastname: string;
}

export interface UserSearchResponse {
  totalCount: number;
  results: Array<UserResponse>;
}

class SystemUserApi {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async get(id: string) : Promise<UserResponse> {
    const params = {
      fields: defaultParams.fields.join(' '),
    };

    return this.axiosInstance.get<UserResponse>(`/api/systemusers/${id}`, { params })
      .then(resp => resp.data);
  }

  async search(searchTerm: string) : Promise<UserSearchResponse> {
    const body = {
      ...defaultParams,
      filter: {
        or: getOrSearchCriteria(searchTerm, searchFields),
      },
    };

    return this.axiosInstance.post<UserSearchResponse>('/api/search/systemusers', body)
      .then((resp) => (resp && resp.data
        ? resp.data
        : Promise.reject(new Error('Unable to search users'))));
  }
}

const api = new SystemUserApi();
export default api;
